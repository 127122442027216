export const makeStringNames = [
  "2 Bar",
  "7oceans",
  "Aage Nielsen",
  "AB",
  "Abbott",
  "Abeking & Rasmussen",
  "Able",
  "Absolute",
  "Active Thunder",
  "Admiral",
  "Adrenaline",
  "Advantage",
  "Aicon",
  "Airship",
  "Alajuela",
  "Alarnia",
  "Albemarle",
  "Alberg Sailboats",
  "Albin",
  "Alden",
  "Alen",
  "Alerion",
  "Alfastreet",
  "Alia Yachts",
  "All Ocean Yachts",
  "Allied",
  "Allmand",
  "Almar",
  "Aloha",
  "Alsberg Brothers",
  "Altima",
  "Aluminum boats inc",
  "ALUMINUM CRUISERS",
  "Alura",
  "Amazon",
  "Amel",
  "AmeraCat",
  "American Multihulls",
  "American Tug",
  "Andrews",
  "Andros",
  "Angel",
  "Angler",
  "Anka",
  "Antares",
  "Antigua",
  "Anvera",
  "Apache",
  "Apex",
  "Apex Marine, Inc.",
  "Apollonian Yachts",
  "Apreamare",
  "Aquarius",
  "Aquasport",
  "Aquila",
  "Archipelago",
  "Arcona",
  "Argos",
  "Arksen",
  "Armstrong Marine",
  "Arno Leopard",
  "ArrowCat",
  "Aspen",
  "Astinor",
  "Astondoa",
  "Atlantic",
  "Atlantis",
  "Atlas Boat Works",
  "AvA Yachts",
  "Avalon",
  "Avance",
  "Avanti",
  "Avenger",
  "Aviara",
  "Axopar",
  "Azimut",
  "Azure",
  "Azuree",
  "Baba",
  "Back Cove",
  "Baglietto",
  "Baha Cruisers",
  "Bahama",
  "Baia",
  "Baja",
  "Bali",
  "Baltic",
  "Banjer",
  "Barker Boatworks",
  "Barletta",
  "Baron",
  "Bavaria",
  "Bayfield",
  "Bayliner",
  "Beachcat",
  "Beachcraft",
  "Belize",
  "Belzona",
  "Beneteau",
  "Beneteau America",
  "Benetti",
  "Bennington",
  "Bering",
  "Bertram",
  "Billfish",
  "Birchwood",
  "Birdsall",
  "Black Thunder",
  "Black Watch",
  "Blackfin",
  "BlackJack",
  "Blackman",
  "Blackwater",
  "Blackwell",
  "Blackwood",
  "Blazer",
  "Block Island",
  "Blount",
  "Blue Jacket",
  "Blue Water",
  "Blue Wave",
  "Bluefin",
  "Bluegame",
  "Bluewater",
  "Bluewater Sportfishing",
  "Bluewater Yachts",
  "Boca Grande",
  "Bombay",
  "Bonadeo",
  "Bord a Bord",
  "Boston Boatworks",
  "Boston Whaler",
  "Bounty",
  "BRABUS",
  "Breaux Bay Craft",
  "Breaux Brothers",
  "Brewer",
  "Brig",
  "Bristol",
  "Bristol Channel Cutter",
  "Broadblue",
  "Broward",
  "Brownell",
  "Bruce King",
  "Bruce Roberts",
  "Bruckmann",
  "Bruno & Stillman",
  "Buddy Cannady",
  "Buddy Davis",
  "Burger",
  "Burns Craft",
  "Bützfleth",
  "Buzzards Bay",
  "C&C",
  "C.E. Ryder",
  "C.W. Hood",
  "Cabo",
  "Cabo Rico",
  "Cabriolet Royale",
  "Caison",
  "CAL",
  "Caliber",
  "Californian",
  "Calyber",
  "Camano",
  "Camargue",
  "Cambria",
  "Camden",
  "Campbell 39",
  "Camper & Nicholsons",
  "Canadian Sailcraft",
  "Canados",
  "Canard Yachts",
  "Canelli",
  "Canoe Cove",
  "Cantieri Navali del Mediterraneo",
  "Canyon Bay",
  "Cape Dory",
  "Cape Fear",
  "Cape George",
  "Cape Horn",
  "Cape Powercat",
  "Capelli",
  "Capital",
  "Capoforte",
  "Capri",
  "Caravelle",
  "Carolina Classic",
  "Carolina Skiff",
  "Carroll Marine",
  "Carter",
  "Carver",
  "Cascade",
  "Castagnola",
  "Catalina",
  "Catamaran",
  "Catana",
  "Cavileer",
  "Cayman",
  "Caymas",
  "C-Catamarans",
  "CDK Technologies",
  "Century",
  "CG Boat Works",
  "CH Marine",
  "Champion",
  "Chaparral",
  "Charter Cat",
  "C-Hawk",
  "CHB",
  "Cheoy Lee",
  "Cheoy Lee Clipper",
  "Cherubini",
  "Chesapeake",
  "Chris-Craft",
  "Christensen",
  "Chuck Paine",
  "Cigarette",
  "CL Yachts",
  "Class 40",
  "Classic",
  "Classic Coaster",
  "Classic Cruiser",
  "CMN",
  "CNB",
  "CNM",
  "Coastal Craft",
  "Cobalt",
  "Cobia",
  "Codecasa",
  "Colin Archer",
  "Columbia",
  "Columbia Yacht",
  "Columbus Yachts",
  "Colvin",
  "Comfortbatar",
  "Comitti",
  "Commander",
  "Commercial",
  "Com-Pac",
  "Composite Yacht",
  "Concept",
  "Conch",
  "Concorde",
  "Concordia",
  "Condor",
  "Contender",
  "Contest",
  "Cookson",
  "Cooper",
  "Cooper Marine",
  "Corbin",
  "Coronado",
  "Corsair",
  "Corsair Marine",
  "Covey Island",
  "Cranchi",
  "Crealock",
  "Crescent",
  "Crest",
  "Crevalle",
  "Crocker",
  "Crosby",
  "Crown",
  "Crownline",
  "Crowther",
  "Cruisers",
  "Cruisers Sport Series",
  "Cruisers Yachts",
  "Crystaliner",
  "CS",
  "CSY",
  "CT",
  "C-Tender",
  "Custom",
  "Custom Carolina",
  "Custom Line",
  "Custom Motor Yacht",
  "Custom Work Barge",
  "Cutwater",
  "Dakota",
  "Dargel",
  "Davidson",
  "Davis",
  "Dawson Yachts",
  "Daytona",
  "De Antonio Yachts",
  "de Haas Shipyard",
  "De Vries Lentsch",
  "Dean",
  "Deep Impact",
  "Deerfoot",
  "DeFever",
  "Defiance",
  "Dehler",
  "Delphia",
  "Delta",
  "Delta Boat Company",
  "Delta Marine",
  "Delta Powerboats",
  "Denison",
  "Design Concepts",
  "Dettling",
  "Devlin",
  "Dickerson",
  "Diesel Duck",
  "Dix Harvey",
  "Dixon",
  "Doggersbank",
  "Dolphin",
  "Dominator",
  "Don Smith",
  "Donzi",
  "Doral",
  "Douglas",
  "Downeast",
  "Dragonfly",
  "Drakkar",
  "Duffy",
  "Dufour",
  "Dusky",
  "DutchCraft",
  "Dyer",
  "Dynamic",
  "Eagle",
  "Eastbay",
  "Eastern",
  "Eastport",
  "Ebbtide",
  "Edgewater",
  "Egg Harbor",
  "Egret",
  "Elan",
  "Elco",
  "Eliminator",
  "Elliott",
  "Ellis",
  "Endeavour",
  "Endeavour Catamaran",
  "Endurance",
  "Envision",
  "Ericson",
  "Euromarine",
  "Everglades",
  "Evo Yachts",
  "Evolve",
  "Excess",
  "Explorer",
  "Express",
  "Express Yachting",
  "Extreme Boats",
  "F&S",
  "Faeton",
  "Fairbanks",
  "Fairline",
  "Falcon",
  "Farallon",
  "Farmont",
  "Farr",
  "Farrier",
  "Fast Passage",
  "Fathom Yachts",
  "FB Design",
  "Feadship",
  "Ferretti Yachts",
  "Ferry",
  "Fiart",
  "Fiberform",
  "FinCat",
  "Finot",
  "Finseeker",
  "Fisher",
  "Fjord",
  "Flagship",
  "Floe Craft",
  "Forbes Cooper",
  "Formosa",
  "Formula",
  "Fortier",
  "Forza",
  "Fountain",
  "Fountaine Pajot",
  "Four Winns",
  "Frans Maas-LeFevre / Holland",
  "Fratelli Aprea",
  "Frauscher",
  "Freedom",
  "Freedom Yachts",
  "Freeman",
  "French Yachts",
  "Frers",
  "Front Runner",
  "Fuji",
  "G&S",
  "Galeon",
  "Gamefisherman",
  "Gar Wood",
  "Garlington",
  "Gause Built",
  "Gemini",
  "G-Force",
  "Gianetti",
  "Glacier Bay",
  "Gladding Hearn",
  "Glasstream",
  "Glastron",
  "Glen-L",
  "Globe",
  "Goetz",
  "Goldfish",
  "Goman",
  "Gorbon",
  "Gozzard",
  "Grady-White",
  "Granada",
  "Grand Alaskan",
  "Grand Banks",
  "Grand Craft",
  "Grand Mariner",
  "Grand Soleil",
  "Granocean",
  "Great Harbour",
  "Greenline",
  "Gulf",
  "Gulf Commander",
  "Gulf Craft",
  "Gulf Stream Yachts",
  "Gulfstar",
  "Gunboat",
  "Guthrie",
  "Hacker-Craft",
  "Hallberg-Rassy",
  "Hallett",
  "Halmatic",
  "Hammer Yachts",
  "Hampton",
  "Hanover",
  "Hans Christian",
  "Hanse",
  "Hardin",
  "Hargrave",
  "Harris",
  "Harris Cuttyhunk",
  "Hatteras",
  "HCB",
  "Heesen",
  "Henriques",
  "Heritage East",
  "Heritage Yacht",
  "Heritage Yachts",
  "Herreshoff",
  "Highfield",
  "Hinckley",
  "Hinckley Sport Boats",
  "Hines-Farley",
  "Hinterhoeller",
  "Hi-Star",
  "Historic",
  "Hi-Tech",
  "Hodgdon",
  "Holland Jachtbouw",
  "Homemade",
  "Horizon",
  "Houseboat",
  "Huckins",
  "Hudson",
  "Hudson Force",
  "Hughes",
  "Hunt Yachts",
  "Hunter",
  "Hustler",
  "Hutchinson",
  "Hyatt",
  "Hydra-Sports",
  "Hydrocat",
  "Hylas",
  "Hyundai",
  "Iguana",
  "Inace",
  "Infiniti",
  "Infinity",
  "Innovator",
  "Insetta",
  "Integrity",
  "Intermarine",
  "Intrepid",
  "Invictus",
  "Invincible",
  "Irwin",
  "ISA",
  "Isara",
  "Island Gypsy",
  "Island Hopper",
  "Island Packet",
  "Island Pilot",
  "Island Runner",
  "Island Spirit",
  "Island Trader",
  "Islander",
  "Italia Yachts",
  "J Boats",
  "Jaguar",
  "Jarrett Bay",
  "Jarvis Newman",
  "JC",
  "Jeanneau",
  "Jefferson",
  "Jensen",
  "Jersey",
  "Jersey Cape",
  "Jim Smith",
  "John Allmand",
  "John Williams",
  "Johnson",
  "Jones-Goodell",
  "Jongert",
  "Joyce",
  "Judge",
  "Jupiter",
  "Kadey-Krogen",
  "Keizer",
  "Kelly Peterson",
  "Ker",
  "Kettenburg",
  "Kevlacat",
  "Key West",
  "Kha Shing",
  "Kirie",
  "Knight & Carver",
  "Knight Brothers",
  "Kong & Halvorsen",
  "Kong and Halvorsen",
  "Koopmans",
  "Krogen",
  "L&H",
  "Ladenstein",
  "Lafitte",
  "Lagoon",
  "Laguna",
  "Lakeview",
  "Lancer",
  "Lancer Yachts",
  "Landing School",
  "Larson",
  "Latitude 46",
  "Laurent Giles",
  "Lazzara",
  "LeClercq",
  "LeComte",
  "Legacy",
  "Legacy Yachts",
  "Lekker",
  "Leonardo Yachts",
  "Leopard",
  "Liberator",
  "Liberty",
  "Lidgard",
  "Lien Hwa",
  "Limestone",
  "Little Harbor",
  "Little Hoquiam",
  "LM",
  "Long Island",
  "Longline Fishing Vessel",
  "Lowland",
  "Luders",
  "Luhrs",
  "Lydia",
  "Lyman",
  "Lyman Morse",
  "Lyman-Morse",
  "Lynx",
  "MacGregor",
  "Mag Bay",
  "Magna Marine",
  "Magnum",
  "Maine Cat",
  "Mainship",
  "Maiora",
  "Majesty",
  "Mako",
  "Malcolm Tennant",
  "Malo",
  "Mangusta",
  "Manta",
  "Maori",
  "Maple Leaf",
  "MAR.CO",
  "Mares",
  "Mariah",
  "Marine Management",
  "Marine Trader",
  "Mariner",
  "Marinette",
  "Maritime Skiff",
  "Maritimo",
  "Markley",
  "Marlago",
  "Marlin Yachts",
  "Marlineer",
  "Marlow",
  "Marlow-Hunter",
  "Marquis",
  "Marten",
  "Martha's Vineyard Shipyard",
  "Martini",
  "Mason",
  "Mast & Mallet",
  "MasterCraft",
  "Masterfab",
  "Mathews Brothers",
  "Matthews",
  "Maverick",
  "Maverick Yachts",
  "Maverick Yachts Costa Rica",
  "Maxum",
  "Mays Craft",
  "Mazarin",
  "McConaghy",
  "McKinna",
  "MCP",
  "Mediterranean",
  "Melges",
  "Mengi Yay",
  "Menorquin",
  "Meridian",
  "Merritt",
  "Metal Shark",
  "Midnight Express",
  "Midnight Lace",
  "Mikelson",
  "Miller Marine",
  "Mirage",
  "Mirage Yachts",
  "Mitchell Cove",
  "MJM",
  "Mochi Craft",
  "Monachus",
  "Monarch",
  "Monk",
  "Monte Carlo",
  "Monte Carlo Yachts",
  "Monte Fino",
  "Monterey",
  "Monticello",
  "Moody",
  "Morgan",
  "Morrelli & Melvin",
  "Morris",
  "Motion Marine",
  "MTI",
  "MTI Marine Technology Inc",
  "MTI Marine Techonolgy Inc",
  "MTI V Marine Technology Inc",
  "Mull",
  "Mystic Powerboats",
  "Mystica",
  "Najad",
  "Nassima Yacht",
  "Nauset",
  "Nauticat",
  "NauticStar",
  "Nautique",
  "Nautitech",
  "Nautor Swan",
  "Navigator",
  "Neel",
  "Nelson Marek",
  "Neptunus",
  "Nevins",
  "New Zealand Yachts",
  "Newport",
  "Newporter",
  "Newton",
  "Niagara",
  "Nimble",
  "Nimbus",
  "NISI",
  "Nonsuch",
  "Nordhavn",
  "Nordic",
  "Nordic Tug",
  "Nordkapp",
  "Nordlund",
  "Norseman",
  "Norship",
  "Norstar",
  "Nor-Tech",
  "North American",
  "North Pacific",
  "North Wind",
  "NorthCoast",
  "Northern Bay",
  "Northern Marine",
  "Nova",
  "Novamarine",
  "Novatec",
  "Novielli",
  "Novurania",
  "NQEA",
  "Numarine",
  "NX Boats",
  "Ocean",
  "Ocean 1",
  "Ocean Alexander",
  "Ocean Hawk",
  "Ocean Master",
  "Ocean Sport",
  "Ocean Voyager",
  "Ocean Yachts",
  "Oceanco",
  "Oceanfast",
  "Oceania",
  "O'Day",
  "Offshore Yachts",
  "Ohlson",
  "OKEAN",
  "One Design",
  "Onslow Bay",
  "Orca",
  "Oryx",
  "Osprey",
  "Out Island",
  "Outbound",
  "Outer Reef Trident",
  "Outer Reef Yachts",
  "Outerlimits",
  "Ovation",
  "Overblue",
  "Oyster",
  "Pace",
  "Pacemaker",
  "Pacific Mariner",
  "Pacific Seacraft",
  "Pacifica",
  "Packet Craft",
  "Palm Beach",
  "Palm Beach Motor Yachts",
  "Palmer Johnson",
  "Palmetto",
  "Pan Oceanic",
  "Panamera Yacht",
  "Paragon",
  "Pardo",
  "Pardo Yachts",
  "Parker",
  "Passport",
  "Pathfinder",
  "Paul Luke",
  "PDQ",
  "Pearl",
  "Pearson",
  "Pedigree Cat",
  "Pendennis",
  "Pershing",
  "Peterson",
  "Phenom",
  "Phoenix",
  "Pilot",
  "Pirelli",
  "Polar",
  "Poole",
  "Porsche",
  "Post",
  "Powerplay Powerboats",
  "Powerquest",
  "Precision",
  "Premier",
  "President",
  "Prestige",
  "Princess",
  "Privilege",
  "Profil",
  "ProKat",
  "Pro-Line",
  "Promarine",
  "Pronautica",
  "Protector",
  "Prout",
  "Prout International",
  "Provincial",
  "Prowler",
  "Puffin",
  "Pursuit",
  "Queenship",
  "Radon",
  "Radovich",
  "Raider",
  "Rambo",
  "Rampage",
  "Rampage Sport Fishing",
  "Rand",
  "Ranger",
  "Ranger Tugs",
  "Ranger Yachts",
  "Ranieri",
  "Rapido",
  "Real",
  "Regal",
  "Regency Yachts",
  "Regulator",
  "Reichel/Pugh",
  "Release",
  "Release Boatworks",
  "Reliance",
  "Renegade",
  "Revolt",
  "Rhodes",
  "Rinker",
  "Rio Yachts",
  "Riptide",
  "Ritchie Howell",
  "Riva",
  "Riviera",
  "Robalo",
  "Robbins",
  "Robert Perry",
  "Roberts",
  "Robertson",
  "Rodman",
  "Rodriquez",
  "Ronin",
  "Rough Water",
  "Roughwater",
  "Rustler",
  "Rybovich",
  "RYCK",
  "S2",
  "Sabre",
  "Sabreline",
  "SACS",
  "Saffier",
  "Saga",
  "Sailboat",
  "Sailfish",
  "Salona",
  "Salt Shaker",
  "Salthouse",
  "San Juan",
  "Sanlorenzo",
  "Santa Barbara",
  "Santa Cruz",
  "Sargo",
  "Sarnico",
  "Savannah",
  "Saxdor",
  "SAY",
  "Scampi",
  "Scand",
  "Scarab",
  "Scarborough",
  "Schaefer",
  "Scheel",
  "Schionning",
  "Schock",
  "Schucker",
  "Scopinich",
  "Scout",
  "Sea Blade",
  "Sea Born",
  "Sea Cat",
  "Sea Force IX",
  "Sea Fox",
  "Sea Hawk",
  "Sea Hunt",
  "Sea Ox",
  "Sea Pro",
  "Sea Ranger",
  "Sea Ray",
  "Sea Water",
  "SeaArk",
  "SeaCraft",
  "Seahorse",
  "SeaHunter",
  "Sealine",
  "Sea-Lion",
  "Seanfinity",
  "SeaProp",
  "Seascape",
  "Seaswirl",
  "Seaswirl Striper",
  "SeaTek",
  "SeaVee",
  "Seaward",
  "Seaway",
  "Seawind",
  "Seaworthy",
  "Seidelmann",
  "Selene",
  "Sensation Yachts",
  "Serenity",
  "Sessa Marine",
  "Shallow Master",
  "Shallow Sport",
  "Shannon",
  "Shearline",
  "ShearWater",
  "Siar Moschini",
  "Sigma",
  "Silent",
  "Silver Ships",
  "Silver Streak",
  "Silverhawk",
  "Silverton",
  "Sirena",
  "Skater",
  "Skeeter",
  "Skipper-BSK",
  "Skipperliner",
  "Skookum",
  "Skorgenes",
  "Slickcraft",
  "Slocum",
  "Smoky Mountain",
  "Smuggler",
  "Snug Harbor",
  "Solace",
  "Solara",
  "Solaris Power",
  "Sonic",
  "Southerly",
  "Southern Cross",
  "Southport",
  "Soverel",
  "Sparkman & Stephens",
  "Sparks",
  "Spectre",
  "Spencer",
  "Spencer Yachts",
  "Spindrift",
  "Sport-Craft",
  "Sportsman",
  "SPX RIB",
  "Stamas",
  "StanCraft",
  "Stapleton",
  "Starcraft",
  "Stardust Cruisers",
  "Statement",
  "Stealth",
  "Steiger Craft",
  "Stephens",
  "Stephens Brothers",
  "Stevens",
  "Stilecatalini",
  "Stingray",
  "Stolper",
  "Stoner",
  "Storebro",
  "Stratos",
  "Streamline",
  "Strike",
  "Striker",
  "Striper",
  "Stuart",
  "Sumerset",
  "Sun Tracker",
  "Sunny Briggs",
  "Sunreef",
  "Sunsation",
  "Sunseeker",
  "Sutphen",
  "Swiftships",
  "Switch",
  "Sylvan",
  "Symbol",
  "Ta Chiao",
  "Ta Shing",
  "Tag",
  "Targa",
  "Tarrab",
  "Tartan",
  "Tayana",
  "Techno Express",
  "Technohull",
  "Technologie Marine",
  "Tecnomar",
  "Tecnorib",
  "Ted Brewer",
  "Tempest",
  "Tesoro",
  "Thomas",
  "Thomas Marine",
  "Thresher",
  "Tiara Sport",
  "Tiara Yachts",
  "Tides",
  "Tidewater",
  "Tillotson-Pearson",
  "Titan",
  "Tofinou",
  "Tollycraft",
  "Topaz",
  "Torres",
  "Transpacific Marine",
  "TransWorld",
  "Trimaran",
  "Trinity Yachts",
  "Tripp",
  "Triton",
  "Trojan",
  "Trophy",
  "True North",
  "True World Marine",
  "Trumpy",
  "Tuna",
  "Tung Hwa",
  "Twin Vee",
  "Two Oceans",
  "Tyler",
  "Ugly Boat",
  "Ulstein",
  "Uniesse",
  "Uniflite",
  "US Navy",
  "Vagabond",
  "Valentino",
  "Valhalla Boatworks",
  "Valiant",
  "Van der Valk",
  "Van Mill",
  "Vancouver",
  "Vanderbilt",
  "VanDutch",
  "Vanquish",
  "Vanquish Yachts",
  "Vantare",
  "Vashon",
  "Vector",
  "Vela",
  "Velocity",
  "Ventura",
  "Venture",
  "Vic Franck",
  "Vicem",
  "Victoria",
  "Viking",
  "Viking Boats",
  "Viking Princess",
  "Viking Sport Cruisers",
  "Vindo",
  "Vineyard Vixen",
  "Vintage",
  "VisionF",
  "Vista",
  "Voyage",
  "Voyage Yachts",
  "Vripack",
  "VSY",
  "VZ",
  "Wally",
  "Watercraft",
  "Waterline",
  "Watkins",
  "Wauquiez",
  "W-Class",
  "Weaver",
  "Webbers Cove",
  "Weldcraft",
  "Wellcraft",
  "Wellington",
  "Wesmac",
  "West Bay",
  "Westcoast",
  "Westlake",
  "Westport",
  "Westsail",
  "Westship",
  "Wharram",
  "Wheeler",
  "Whitby",
  "Whitewater",
  "Whiticar",
  "Wider",
  "Wilbur",
  "Willard Marine",
  "William Garden",
  "Willie Boats",
  "Willis",
  "Windship",
  "Windward",
  "Windy",
  "Winslow",
  "Winter Custom Yachts",
  "World Cat",
  "Wraceboats",
  "Wright Performance",
  "Wrighton",
  "X Shore",
  "Xcelerator Boatworks",
  "Xquisite Yachts",
  "X-Yachts",
  "Yachting Developments",
  "Yamaha Boats",
  "Yellowfin",
  "York",
  "Young Brothers",
  "Young Sun",
  "Yuka Yacht",
  "Zar",
  "ZCB",
  "Zeelander",
  "Zeta",
  "Zeta Elle",
  "Zimmerman",
  "Zodiac",
];
