export const classNames = [
  "Antique and Classics",
  "Barge",
  "Beach Catamarans",
  "Catamaran",
  "Center Cockpit",
  "Center Consoles",
  "Class Name",
  "Commercial Boats",
  "Convertible Boats",
  "Cruisers",
  "Cuddy Cabin",
  "Cutter",
  "Daysailers",
  "Deck Saloon",
  "Dinghies",
  "Dual Console",
  "Express Cruiser",
  "Flybridge",
  "Gulet",
  "High Performance Boats",
  "Ketch",
  "Mega Yachts",
  "Motor Yachts",
  "Motorsailers",
  "Multi-Hulls",
  "Pilothouse",
  "Power Catamarans",
  "Racers",
  "Racers and Cruisers",
  "Runabouts",
  "Saltwater Fishing",
  "Schooner",
  "Sloop",
  "Sports Cruiser",
  "Sports Fishing Boats",
  "Trimaran",
  "Unspecified",
  "Yawl",
];
